import * as client_hooks from '../../../src/app/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(app)": [~6,[2]],
		"/callback": [14],
		"/(app)/companies": [~7,[2]],
		"/(app)/companies/[id]": [~8,[2,3]],
		"/(app)/companies/[id]/advanced-analytics": [~9,[2,3]],
		"/(app)/companies/[id]/forecasts": [~10,[2,3]],
		"/(app)/industries/[slug]": [11,[2]],
		"/login": [~15],
		"/logout": [16],
		"/(app)/search": [12,[2]],
		"/showcase/charts": [~17,[4,5]],
		"/showcase/charts/charts-edge": [18,[4,5]],
		"/showcase/charts/contributions": [19,[4,5]],
		"/showcase/charts/emissions": [20,[4,5]],
		"/showcase/charts/stack": [21,[4,5]],
		"/showcase/charts/switchable": [22,[4,5]],
		"/showcase/chat": [23,[4]],
		"/showcase/colors": [24,[4]],
		"/showcase/components": [25,[4]],
		"/showcase/numbers": [26,[4]],
		"/showcase/scenarios": [27,[4]],
		"/showcase/typography": [28,[4]],
		"/(app)/whats-new": [~13,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';